@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Lobster';
  src: url('./assets/fonts/Lobster/Lobster-Regular.ttf');
}

*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
  color: #2B2D42;
  font-weight: 700;
  margin: 0 0 10px;
}

a {
  color: #B9BABC;
  font-weight: 500;
  text-decoration: none;
  transition: 0.2s color;
}

a:hover, a:focus {
  color: #D10024;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none
}

.container {
  max-width: 1440px;
  margin: 83px auto 0px;
}

h2.title {
  padding: 32px 24px 12px;
  width: fit-content;
  margin: auto;
  text-align: center;
  border-bottom: 2px solid #ccc;
  position: relative;
}

h2.title::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  width: 30%;
  height: 3px;
  background-color: #D10024;
}

.primary-btn,
.secondary-btn {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  background-color: #D10024;
  cursor: pointer;
  padding: 12px 36px;
  border: 3px solid transparent;
  border-radius: 30px;
  transition: 0.3s;
}

.primary-btn:hover {
  color: #D10024;
  background-color: #fff;
}

.secondary-btn:hover {
  color: #D10024;
  background-color: #fff;
  border-color: #D10024;
}

@media only screen and (max-width: 768px) {  
  .primary-btn,
  .secondary-btn {
    font-size: 16px;
    padding: 12px 24px;
  }
}

@media only screen and (max-width: 600px) {  
  body {
    font-size: 14px;
  }
}

@media only screen and (max-width: 480px) {  
  body {
    font-size: 13px;
  }
}
.container {
    max-width: 1440px;
    margin: 83px auto 0px;
}

.cart {
    margin: 120px 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.cart-list {
    width: 70%;
    border: 2px solid #d1d2d3;
    border-radius: 12px;
    padding: 16px 24px;
}

.cart-list h3 {
    margin: 14px 0px;
}

.cart-list .row {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 2px solid #d1d2d3;
    padding: 15px 0;
}

.cart-list .row:last-child {
    border-bottom: none;
}

.cart-list .row.header {
    padding: 0;
}

.cart-list .row.header .col h5 {
    color: #757575;
    text-transform: uppercase;
    margin: 22px 0px;
}

.cart-list .row.header .col:first-child {
    justify-content: center;
}

.cart-list .row .col {
    flex: 1;
    display: flex;
}

.cart-list .row .col,
.cart-list .row .col h5 {
    margin-bottom: 0px;
}

.cart-list .col.card-item-desc {
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
}

.cart-list .card-item-image {
    width: 140px;
    height: 140px;
    min-height: 140px;
    min-width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-list .card-item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cart-list .card-item-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 250px;
}

.cart-list .card-item-info h3 {
    color: #212121;
    margin: 0px 0px 10px;
}

.cart-list .card-item-info h3:hover {
    color: #D10024;
}

.cart-list .card-item-info .feature {
    color: #8D99AE;
    display: flex;
    gap: 8px;
}

.cart-list .card-item-info .feature h4 {
    margin: 0;
    color: #444;
}

.cart-list .col.card-item-controls {
    display: flex;
    align-items: center;
}

.cart-list .card-item-price,
.cart-list .card-item-quantity, 
.cart-list .card-item-total,
.cart-list .card-item-remove {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-list .card-item-remove {
    flex: 0.5;
}

.cart-list .card-item-quantity {
    gap: 20px;
}

.cart-list .card-item-quantity button {
    width: 24px;
    height: 24px;
    background: #D10024;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-list .card-item-remove button {
    width: 35px;
    height: 35px;
    background-color: #d1d2d3;
    background-color: #E5E5E5;
    color: #212121;
    font-size: 20px;
    border-radius: 50px;
    text-align: center;
    line-height: 30px;
    outline: none;
    border: none;
    cursor: pointer;
}

.cart-summary {
    width: 25%;
    box-sizing: border-box;
    border: 2px solid #d1d2d3;
    border-radius: 12px;
    padding: 24px 36px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.cart-summary h3 {
    margin: 0;
    padding: 16px 0px;
    border-bottom: 2px solid #d1d2d3;
}

.cart-summary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
}

.cart-summary-item h4 {
    margin: 0;
}

.cart-summary a {
    margin: 0 auto;
}

@media only screen and (max-width: 1200px) {
    .cart {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .cart-list {
        width: 100%;
    }
    .cart-list .col.card-item-desc {
        flex: 2;
    }
    .cart-list .row.header .col:first-child {
        flex: 2;
    }
    .cart-list .col.card-item-controls {
        flex: 1.5;
    }
    .cart-summary {
        width: 440px;
    }
}

@media only screen and (max-width: 768px) {
    .cart-list .row.header {
        display: none;
    }
    .cart-list .row {
        flex-direction: column;
        gap: 30px;
    }
    .cart-list .col.card-item-desc {
        width: 100%;
    }
    .cart-list .col.card-item-controls {
        position: relative;
        width: 100%;
        justify-content: flex-start;
        gap: 72px;
    }
    .cart-list .card-item-quantity {
        max-width: fit-content;
        margin-left: 15px;
    }
    .cart-list .card-item-total {
        max-width: fit-content;
    }
    .cart-list .card-item-remove {
        max-width: fit-content;
        position: absolute;
        right: 12px;
    }
    .cart-list .card-item-price {
        display: none;
    }
}

@media only screen and (max-width: 480px) {
    .cart-summary {
        width: 100%;
    }
}
@font-face {
    font-family: 'Lobster';
    src: url('../assets/fonts/Lobster/Lobster-Regular.ttf');
}

.nav {
    max-width: 1440px;
    padding: 24px 36px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #15161D;
    color: #fff;
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 99;
} 

.nav h3, 
.nav a {
    color: #fff;
}

.logo {
    font-family: 'Lobster', sans-serif;
    font-style: italic;
    font-size: 28px;
    margin: 0px;
}

.logo span {
    color: #D10024;
}

.nav-links {
    display: flex;
    gap: 40px;
}

.drop-links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #15161D;
    color: #fff;
    width: 100%;
}

.nav-links li {
    position: relative;
}

.drop-links li {
    position: relative;
    width: fit-content;
    padding: 16px 0px;
}

.nav-links .underline, 
.drop-links .underline {
    width: 0px;
    height: 2px;
    background: #D10024;
    position: absolute;
    z-index: 10;
    bottom: -5px;
    transition: 0.2s all;
}

.drop-links .underline {
    bottom: 8px;
}

.nav-links a:hover,
.nav-links a.active,
.drop-links a:hover,
.drop-links a.active {
    color: #D10024;
}

.nav-links a:hover ~ .underline,
.nav-links a.active ~ .underline,
.drop-links a:hover ~ .underline,
.drop-links a.active ~ .underline {
    width: 100%;
}

.nav-controls {
    font-size: 20px;
    display: flex;
    gap: 16px;
}

.nav-controls svg {
    cursor: pointer;
}

.nav-controls svg:hover {
    color: #D10024;
}

.menu-icon {
    display: none;
}

.cart-btn {
    position: relative;
}

.cart-btn .qty {
    width: 18px;
    height: 18px;
    line-height: 18px;
    background-color: #D10024;
    color: #fff;
    border-radius: 50%;
    font-size: 10px;
    position: absolute;
    top: -8px;
    right: -16px;
    text-align: center;
}

@media only screen and (max-width: 768px) {  
    .nav {
        flex-wrap: wrap;
    }
    .menu-icon {
        display: block;
    }
    .nav-links {
        display: none;
    }
    .drop-links {
        display: none;
    }
    .drop-links.active {
        display: flex;
    }
}
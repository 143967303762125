.container {
    max-width: 1440px;
    margin: 83px auto 0px;
}

h2.title {
    text-transform: capitalize;
}

.items {
    display: flex;
    flex-wrap: wrap;
    margin: 45px 35px;
    gap: 30px;
}
.item-box {
    width: clamp(260px, 25% - 20px, 320px);
    border: 2px solid #d1d2d3;
    border-radius: 10px;
    text-align: center;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: display 1s;
}

@keyframes display {
    from {
        bottom:-140px;
        opacity:0
    } 
    to
    {
        bottom:0;
        opacity:1
    }
}

.item-image {
    position: relative;
    padding: 25px 15px 15px;
    box-sizing: border-box;
    width: 100%;
    height: 280px;
}

.item-image a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-image a img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: 0.4s;
}

.item-image:hover img {
    transform: scale(1.1);
}

.item-image .labels {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    flex-direction: row-reverse;
    gap: 4px;
}

.labels span {
    border: 2px solid;
    padding: 2px 10px;
    font-size: 12px;
}

.labels .new {
    background-color: #D10024;
    border-color: #D10024;
    color: #fff;
}

.labels .percent {
    background-color: #fff;
    border-color: #D10024;
    color: #D10024;
}

.item-body {
    padding: 15px;
}

.item-body .brand {
    display: block;
    font-size: 22px;
    margin-bottom: 12px;
}

.item-body .name {
    font-size: 26px;
    margin-bottom: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.item-price {
    background-color: #d1d2d3;
    height: 75px;
    padding-inline: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.final-price {
    font-size: 20px;
    font-weight: 700;
    color: #D10024;
    margin-right: 12px;
}

.old-price {
    font-size: 16px;
    color: #7E7E7E;
}

.item-price a {
    padding: 8px 30px;
}

@media only screen and (max-width: 1200px) {  
    .item-box {
        width: clamp(240px, 33% - 20px, 340px);
    }
}

@media only screen and (max-width: 992px) {  
    .item-box {
        width: clamp(240px, 50% - 20px, 380px);
    }
}

@media only screen and (max-width: 992px) {  
    .final-price {
        font-size: 18px;
    }
    .old-price {
        font-size: 14px;
    }
}

@media only screen and (max-width: 600px) {  
    .item-box {
        width: clamp(250px, 100%, 380px);
    }
}